<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('system_job.detailDialog') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="viewFlag"
      />
    </div>
    <div slot="footer">
      <el-button
        @click="handleDialogClose"
      >
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
const BASEURL = {
  update: '/download/quartz/update',
  get: '/download/quartz/get',
  save: '/download/quartz/save'
}
export default {
  name: 'SystemUserDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      formModel: {}
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },

    status () {
      return this.$getDictList('data_status')
    },

    formField () {
      return [
        {
          prop: 'name',
          label: this.$t('system_job.name'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },
        {
          prop: 'group',
          label: this.$t('system_job.group'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },
        {
          prop: 'className',
          label: this.$t('system_job.className'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },
        {
          prop: 'cronExpression',
          label: this.$t('system_job.cronExpression'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },
        {
          prop: 'description',
          label: this.$t('system_job.description'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },
        {
          prop: 'state',
          label: this.$t('system_job.state'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          component: { disabled: true }
        }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },
    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          const submitModel = this.$_.cloneDeep(this.formModel)
          this.$axios
            .post(this.detailData.id ? BASEURL.update : BASEURL.save, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleDialogOpen () {
      if (!this.detailData.id) return
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      this.$axios
        .post(BASEURL.get, { id: this.detailData.id })
        .then(resp => {
          const respData = resp.data
          this.formModel = respData
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = {}
    }
  }
}
</script>
